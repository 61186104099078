import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import Package from '../package.json';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/index.css';
import { GA_TRACKING_ID } from './config';

ReactGA.initialize(GA_TRACKING_ID)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter basename={Package.homepage}>
			<App />
		</BrowserRouter>
	</React.StrictMode>
);