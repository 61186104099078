import axios from 'axios'
import { API_URL } from '../config'

export function requestToken() {
	return axios({
		method: "get",
		url: `${API_URL}/get-token/`,
		headers: {
			'latitude': localStorage.getItem('latitude'),
			'longitude': localStorage.getItem('longitude')
		}
	}).then(response => {
		if (response.data.code === '00'){
			localStorage.setItem('token', response.data.data.token)
		}
	}).catch(error => {
		console.log(error)
	})
}

export function requestGet(endpoint, params=null) {
	let token = localStorage.getItem('token')
	let config = {
		method: "get",
		url: API_URL + endpoint,
		params: params,
		headers: {
			'Authorization': `Bearer ${ token }`,
			'Access-Control-Allow-Headers': 'Content-Type, x-requested-with',
			'Access-Control-Allow-Origin':'*',
			'latitude': localStorage.getItem('latitude'),
			'longitude': localStorage.getItem('longitude')
		}
	}
	return axios(config)
}


export function requestPost(endpoint, data) {
	let token = localStorage.getItem('token')
	let config = {
		method: "post",
		url: API_URL + endpoint,
		data: data,
		headers: {
			'Authorization': `Bearer ${ token }`,
			'Content-Type': 'application/x-www-form-urlencoded',
			'latitude': localStorage.getItem('latitude'),
			'longitude': localStorage.getItem('longitude')
		}
	}
	return axios(config)
}