import React, { Component } from 'react';
import { requestGet, requestToken } from '../axios';
import { Loader } from '../components/Other';
import { ActionButton } from '../components/Button';
import { FilterSelect } from '../components/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faLocationArrow } from '@fortawesome/free-solid-svg-icons';


export default class Result extends Component {
	constructor(props){
		super(props)
		this.state = {
			data: null,
			filter: [],
			can_filter: false,
			param: this.props.navigation.state.param,
			page: 1,
			hasNext: true,
			loading: false
		}
	}

	componentDidMount() {
		this.getResult(this.props.navigation.state.param)
	}

	getResult(param) {
		requestGet('/result/', param).then(response => {
            if (response.data.code === '00') {
				let filter = []

				for (let val of response.data.data.filter_list) {
					filter.push({'value': val, 'label': val})
				}

                this.setState({
					data: response.data.data.data,
					filter: filter,
					can_filter: response.data.data.can_filter,
					hasNext: response.data.data.has_next
				})
            }
        }).catch(async(error) => {
            if (error.response.status === 401){
                await requestToken()
                this.getResult(param)
            }
        })
	}

	updateData(type, param, page) {
		this.setState({loading: true})

		requestGet('/result/', param).then(response => {
            if (response.data.code === '00') {
				if (type === 'add') {
					let data = this.state.data
					
					for (let item of response.data.data.data){
						data.push(item)
					}

					this.setState({
						data: data,
						page: page,
						param: param,
						hasNext: response.data.data.has_next,
						loading: false
					})
				}
				else if (type === 'update'){
					let data = response.data.data.data

					this.setState({
						data: data,
						page: page,
						param: param,
						hasNext: response.data.data.has_next,
						loading: false
					})
				}				

               
            }
        }).catch(async(error) => {
            if (error.response.status === 401){
                await requestToken()
                this.updateData(param)
            }
        })
	}

	loadMore() {
		let param = this.state.param
		param['page'] = this.state.page + 1

		this.updateData('add', param)
	}

	filterCategory(val) {
		let param = this.state.param
		let filter = []

		for (let x of val){
			filter.push(x['value'])
		}

		param['page'] = 1
		param['filter'] = JSON.stringify(filter)
		this.updateData('update', param, 1)
	}

    render() {	
        return (
            <>
				{!this.state.data ?
					<Loader />
				:
					<>
						<div className="col-12 my-4">
							{this.state.can_filter ? 
								<FilterSelect 
									name="category" 
									options={this.state.filter} 
									placeholder={this.state.param.tag === 'FOOD' ? 'Pilih Kategori' : this.state.param.tag === 'EMBASSY' ? 'Pilih Negara' : null}
									onChange={(val) => this.filterCategory(val)} />
							: null}
						</div>

						<div className="col-12">
							{this.state.data.map(item => {
								return (
									<div key={item.id} className="item-list" onClick={() => this.props.goToDetail({'tag': this.state.param.tag, 'id': item.id})}>
										<div className="row">
											<div className="col-4">
												<div className="ratio ratio-1x1">
													<img className="img-fluid" src={item.image} alt={item.name} />
												</div>
											</div>
											<div className="col-8">
												<h2>{item.name}</h2>
												<div className="row">
													<div className="col-1"><p><FontAwesomeIcon icon={faLocationArrow} /></p></div>
													<div className="col"><p>{item.distance} km</p></div>
												</div>
												<div className="row">
													<div className="col-1"><p><FontAwesomeIcon icon={faLocationDot} /></p></div>
													<div className="col"><p>{item.address}</p></div>
												</div>
												{item.tags ?
													<div className="row">
														<div className="col">
															{item.tags.map(tag => 
																<span key={`${item.name}-${tag}`} className="badge badge-sml">{tag}</span>
															)}
														</div>
													</div>
												: null}
											</div>
										</div>
									</div>
								)
							})}
						</div>
						
						{this.state.hasNext ?
							<div className="col-12 mb-3 text-center">
								<ActionButton icon={false} text="SELANJUTNYA" onClick={() => this.loadMore()} loading={this.state.loading} />
							</div>
						: null}
					</>
				}
            </>
        )
    }
}
