import Table from 'react-bootstrap/Table';


export function Recommendation(props) {
	return (
		<Table responsive borderless className="h-100 m-0">
			<tbody>
				<tr className="h-100">
					{props.list.map((item, i) => {
						return (
							<td key={i} className="p-1 align-top h-100">
								<div className="recommendation-container h-100" onClick={() => props.onClick({'tag': props.navigation.state.param.tag, 'id': item.id})}>
									<div className="image">
										<img className="img-fluid h-100" src={item.image} alt={item.title}/>
									</div>
									<div className="title">
										<h3>{item.name}</h3>
										<p>{item.distance} km</p>
									</div>
								</div>
							</td>
						)
					})}
				</tr>
			</tbody>
		</Table>
	)
}