import { Routes, Route, useLocation } from 'react-router-dom';
import NavbarComponent from './components/Navbar';
import Home from './containers/Home';
import Filter from './containers/Filter';
import Result from './containers/Result';
import Detail from './containers/Detail';
import Emergency from './containers/Emergency';
import ReactGA from 'react-ga';


function App() {
	let location = useLocation()
	let path = location.pathname
	ReactGA.pageview(path);

	return (
		<>
			<NavbarComponent />

			<div className="container mt-6">
				<div className="row justify-content-center">	
					<div className="col-12 col-sm-6">			
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="/filter" element={<Filter />} />
							<Route path="/result" element={<Result />} />
							<Route path="/detail" element={<Detail />} />
							<Route path="/emergency" element={<Emergency />} />
						</Routes>
					</div>
				</div>
			</div>
		</>
	);
}

export default App;
