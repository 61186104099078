import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { requestGet, requestToken } from '../axios';
import Filter from '../screens/Filter';


export default function FilterContainer(props) {
    let navigate = useNavigate()
    let navigation = useLocation()

	function goToList(param) {
        navigate('/result', {state: {'param': param}})
    }

    return <Filter {...props} 
		navigate={navigate} 
        navigation={navigation}
		goToList={goToList} />
}