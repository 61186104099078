

export function checkLocation() {
	navigator.geolocation.getCurrentPosition(
		(position) => {
			localStorage.setItem('latitude', position.coords.latitude)
			localStorage.setItem('longitude', position.coords.longitude)
		},
		(error) => {
			localStorage.setItem('latitude', '')
			localStorage.setItem('longitude', '')
		}
	)
}