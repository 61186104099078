import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Articles, Advertisement } from '../components/Home';
import { checkLocation } from '../functions';
import { requestToken, requestGet, requestPost } from '../axios/';
import { Loader, ModalAlert } from '../components/Other';
import { InputText, InputNumber } from '../components/Form';
import { SubmitButton } from '../components/Button';

export default class Home extends Component {
	constructor(props){
		super(props)
		this.state = {
			activeMenu: 'DIRECTORY',
			menu: null,
			articles: null,
			showAlert: false,
			contentAlert: null,
			loading: false
		}
	}

	componentDidMount() {
		checkLocation()
		this.getHomeMenu()
	}

	getHomeMenu() {
		requestGet('/home/').then(response => {
			if (response.data.code === '00'){
				this.setState({
					menu: response.data.data.menu,
					articles: response.data.data.articles,
					advertisement: response.data.data.advertisement
				})
			}		
		}).catch(async(error) => {
			if (error.response.status === 401){
				await requestToken()
				this.getHomeMenu()
			}
		})
	}

	startTelemedicine(e) {
		e.preventDefault()
		
		this.setState({loading: true})

		let data = new FormData()
		data.append('name', e.target.name.value)
		data.append('phone', e.target.phone.value)

		requestPost('/telemedicine/', data).then(response => {
			if (response.data.code === '00'){
				window.location.href = response.data.data.url
			}
			else {
				console.log(response.data.message)
				this.setState({
					showAlert: true,
					contentAlert: response.data.message,
					loading: false
				})
			}
		}).catch(async(error) => {
			console.log(error)
			if (error.response.status === 401){
				await requestToken()
				this.startTelemedicine(e)
			}
		})
	}

	render() {
		return (
			<>
				{!this.state.menu ?
					<Loader />
				:
				<>
					<div className="col-12 px-0 overflow-hidden">
						<img src={require('../assets/image/header.png')} alt="zurich directory header" />
					</div>
					
					<div className="col-12 my-3 text-center">
						{this.state.activeMenu === 'DIRECTORY'?
							<>
								<h1>Direktori Syariah</h1>
								<p>Terima kasih atas kepercayaan Anda kepada Zurich Syariah untuk memberikan perlindungan perjalanan Anda. Nikmati akses informasi tambahan dari Zurich Syariah untuk memudahkan dan memberikan kenyamanan dalam perjalanan Anda di dalam dan luar negeri.</p>
							</>
						: this.state.activeMenu === 'TELECONSULT'?
						<>
							<h1>Telekonsultasi</h1>
							<p>Dapatkan gratis layanan konsultasi dengan Dokter Umum bersama KlikDokter.</p>
						</>
						: null}
					</div>

					<div className="col-12 mb-3">
						<div className="home-tab-container">
							<div className="row g-0">
								<button
									onClick={() => this.setState({activeMenu: 'DIRECTORY'})}
									className={this.state.activeMenu === 'DIRECTORY' ? "col tab-menu active" : "col tab-menu"}>
										Direktori Syariah
								</button>
								<button
									onClick={() => this.setState({activeMenu: 'TELECONSULT'})}
									className={this.state.activeMenu === 'TELECONSULT' ? "col tab-menu active" : "col tab-menu"}>
										Telekonsultasi
								</button>
							</div>
						</div>
					</div>

					{this.state.activeMenu === 'DIRECTORY'?
						<>
							<div className="col-12 my-3">
								<div className="row g-2">
									{this.state.menu.map(item => {
										return (
											<Menu {...item} key={item.name} onClick={this.props.navigateToFilter} />
										)
									})}
								</div>
							</div>
							<div className="col-12 my-3">
								<div className="row">
									<div className="col">
										<h2 className="text-start">Artikel Menarik</h2>
									</div>
									<div className="col">
										<p className="text-end">
											<Link to="https://weplus.id/article/category/Travel/" className="text-decoration-none">Lihat Lainnya</Link>
										</p>
									</div>
								</div>
								<div className="row mb-3">
									<Articles articles={this.state.articles} onClick={this.props.redirectToArticle}/>
								</div>
								<div className="row mb-3">
									<Advertisement advertisement={this.state.advertisement} onClick={this.props.redirectToArticle}/>
								</div>
							</div>
						</>
					: this.state.activeMenu === 'TELECONSULT'?
						<>
							<div className="col-12 my-3 text-center">
								<img src={require('../assets/image/telemedicine.png')} className="img-fluid" width="240" alt="zurich directory teleconsult" />
							</div>

							<div className="col-12 my-3">
								<form method="post" autoComplete="off" onSubmit={e => this.startTelemedicine(e)}>
									<InputText name="name" label="Nama Lengkap" placeholder="John Doe" required={true} />
									<InputNumber name="phone" label="No Handphone" placeholder="08131234xxxx" required={true} />

									<div className="text-center">
										<SubmitButton text="KONSULTASI" loading={this.state.loading} />
									</div>
								</form>
							</div>
						</>
					: null}

					<div className="col-12 my-3 text-center">
						<p>PT Zurich General Takaful Indonesia berizin dan diawasi oleh OJK</p>
					</div>

					<ModalAlert show={this.state.showAlert} onHide={() => this.setState({showAlert: false})} content={this.state.contentAlert} />
				</>
				}
			</>
		)
	}
}
