import React, { Component } from 'react'
import { useState } from 'react';
import { Nav, Navbar } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faLocationArrow, faBars } from '@fortawesome/free-solid-svg-icons';


function NavbarComponent() {

	const [expanded, setExpanded] = useState(false);
	
	return (
		<Navbar expanded={expanded}  collapseOnSelect className="navbar bg-main" expand="lg" fixed="top">
			<Container fluid className="px-2">
				<Navbar.Brand href="#home">
					<Link to="/"><img src={require('../assets/image/zurich-logo.png')} height="40" alt="Zurich Logo" /></Link>
				</Navbar.Brand>

				<Navbar.Toggle class="border-0 navbar-toggler collapsed" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="collapseExample" onClick={() => setExpanded(!expanded)}>
					<img class="hamburger-menu" src={require('../assets/image/hamburger-menu.png')} height="30"/>
					<img class="icon-close" src={require('../assets/image/icon-close.png')} height="30"/>
				</Navbar.Toggle>

				<Navbar.Collapse id="responsive-navbar-nav" class="navbar-collapse collapse me-auto" aria-expanded="false">
					<Nav className="navbar-nav" >
						<Navbar.Text>
							<div 	className="contact-info text-center" 
									onClick={() => {
									window.location.href = 'https://www.zurich.co.id/'
									setExpanded(!expanded)}}>
								www.zurich.co.id
							</div>
						</Navbar.Text>

						<Navbar.Text>
							<div className="contact-info text-center" onClick={() => {setExpanded(!expanded)}}>
								zurichcare.syariah@zurich.co.id
							</div>
						</Navbar.Text>

						<Navbar.Text>
							<div 	className="contact-info text-center" 
									onClick={() => {
									window.location.href = 'tel:1500-456'
									setExpanded(!expanded)}}>
								<FontAwesomeIcon icon={faPhone} className="align-middle" /> 1500-456
							</div>
						</Navbar.Text>
					</Nav>
				</Navbar.Collapse>
			</Container>
		</Navbar>
	)
	}

export default NavbarComponent;
