import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { requestGet, requestToken } from '../axios';
import Detail from '../screens/Detail';


export default function DetailContainer(props) {
    let navigate = useNavigate()
    let navigation = useLocation()

	function redirect(url){
		window.location.href = url
		return true
	}

    return <Detail {...props} 
		navigate={navigate} 
        navigation={navigation} 
		redirect={redirect} />
}