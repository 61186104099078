import React from 'react';
import { useNavigate } from 'react-router-dom';
import Home from '../screens/Home';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';


export default function HomeContainer(props) {
    let navigate = useNavigate()

	function navigateToFilter(tag, url) {
		if (tag === 'EMERGENCY') {
			navigate('/emergency')
		}
		else if (tag === 'EMBASSY') {
			navigate('/result', {state: {'param': {'tag': tag, 'target': 'location'}}})
		}
		else if (tag === 'ABOUT') {
			window.location.href = `${url}`
		}
		else {
			navigate('/filter', {state: {'tag': tag}})
		}
    }

    function redirectToArticle(url) {
		window.location.href = url
		return true
	}

    return <Home {...props} 
        navigateToFilter={navigateToFilter}
        redirectToArticle={redirectToArticle} />
}