import React, { Component } from 'react';
import { LocationButton, SubmitButton } from '../components/Button';
import { SelectLocation } from '../components/Form';
import { requestGet, requestToken } from '../axios';
import { checkLocation } from '../functions';
import { ModalAlert } from '../components/Other';

export default class Filter extends Component {
    constructor(props) {
        super(props)
        this.state = {
            country_list: [],
            province_list: [],
            city_list: [],
            country: '',
            province: '',
            city: '',
            showModal: false,
            contentModal: null
        }
    }

    componentDidMount() {
		checkLocation()
        this.getCountry()
	}

    getCountry() {
        let param = {
            'tag': this.props.navigation.state.tag,
            'target': 'country'
        }

        requestGet('/search/', param).then(response => {
            if (response.data.code === '00') {
                let country_list = []

                for (var val of response.data.data){
                    country_list.push({'value': val.id, 'text': val.name})
                }

                this.setState({country_list: country_list})
            }
        }).catch(async(error) => {
			if (error.response.status === 401){
				await requestToken()
				this.getCountry()
			}
		})
    }

    getProvince(country) {
        let param = {
            'tag': this.props.navigation.state.tag,
            'target': 'province',
            'country': country,
        }

        requestGet('/search/', param).then(response => {
            if (response.data.code === '00') {
                let province_list = []

                for (var val of response.data.data){
                    province_list.push({'value': val.id, 'text': val.name})
                }

                this.setState({
                    province_list: province_list,
                    province: '',
                    city: ''
                })
            }
        }).catch(async(error) => {
			if (error.response.status === 401){
				await requestToken()
				this.getCity()
			}
		})
    }

    getCity(province) {
        let param = {
            'tag': this.props.navigation.state.tag,
            'target': 'city',
            'province': province,
        }

        requestGet('/search/', param).then(response => {
            if (response.data.code === '00') {
                let city_list = []

                for (var val of response.data.data){
                    city_list.push({'value': val.id, 'text': val.name})
                }

                this.setState({
                    city_list: city_list,
                    city: ''
                })
            }
        }).catch(async(error) => {
			if (error.response.status === 401){
				await requestToken()
				this.getCity()
			}
		})
    }

    filterByPosition() {
        const latitude = localStorage.getItem('latitude')
        const longitude = localStorage.getItem('longitude')
        
        if (!latitude || !longitude){
            this.setState({
                showModal: true,
                contentModal: 'Silakan mengaktifkan lokasi anda untuk mengakses menu ini.'
            })
        }
        else {
            this.props.goToList({
                'tag': this.props.navigation.state.tag, 
                'target': 'position'
            })
        }
    }

    filterByLocation(e) {
        e.preventDefault()

        if (e.target.city.value){
            this.props.goToList({
                'tag': this.props.navigation.state.tag, 
                'target': 'location',
                'city': e.target.city.value
            })
        }
    }

    render() {
        return (
            <>
                <div className="col-12"><LocationButton onClick={() => this.filterByPosition()} /></div>
                <div className="col-12 mb-3 text-center"><p>atau cari berdasarkan</p></div>
                <div className="col-12">
                    <form method="post" autoComplete="off" onSubmit={e => this.filterByLocation(e)}>
                        <SelectLocation 
                            name="country" 
                            required={true} 
                            value={this.state.country}
                            options={this.state.country_list}
                            placeholder="Pilih Negara" 
                            onChange={e => {
                                this.getProvince(e.target.value)
                                this.setState({[e.target.name]: e.target.value})
                            }} />
                        
                        <SelectLocation 
                            name="province" 
                            required={true}
                            value={this.state.province} 
                            options={this.state.province_list}
                            placeholder="Pilih Provinsi"
                            onChange={e => {
                                this.getCity(e.target.value)
                                this.setState({[e.target.name]: e.target.value})
                            }} />

                        <SelectLocation 
                            name="city" 
                            required={true} 
                            value={this.state.city}
                            options={this.state.city_list}
                            placeholder="Pilih Kota"
                            onChange={e => this.setState({[e.target.name]: e.target.value})} />

                        <div className="col-12 text-center">
                            <img className="img-fluid" src={require('../assets/image/filter.png')} alt="filter" width="240" />
                            <p>Temukan tempat terdekat berdasarkan lokasi saat ini atau pilih kota tujuan anda.</p>
                        </div>

                        <SubmitButton text="CARI" />
                    </form>

                    <ModalAlert show={this.state.showModal} onHide={() => this.setState({showModal: false})} content={this.state.contentModal} />
                </div>
            </>
        )
    }
}
