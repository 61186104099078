import React, { Component } from 'react';
import { requestGet, requestToken } from '../axios';
import { Loader, ToastAlert } from '../components/Other';
import { Advertisement } from '../components/Home';
import { ActionButton } from '../components/Button';
import { Recommendation } from '../components/Detail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhone, faLocationArrow, faSquarePhone } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';


export default class Detail extends Component {
	constructor(props){
		super(props)
		this.state = {
			data: null,
			toastText: null,
			toastShow: false
		}
	}

	componentDidMount() {
		this.getDetail(this.props.navigation.state.param)
	}

	getDetail(param) {
		requestGet('/detail/', param).then(response => {
            if (response.data.code === '00') {
				this.setState({data: response.data.data})
            }
        }).catch(async(error) => {
            if (error.response.status === 401){
                await requestToken()
                this.getDetail(param)
            }
        })
	}

	changeDetail(param) {
		this.setState({data: null})
		this.getDetail(param)
	}

	handlePhone(phone) {
		if (isMobile){
			window.location.href = `tel:${phone}`
		}
		else {
			navigator.clipboard.writeText(phone)

			this.setState({
				toastShow: true,
				toastText: `Nomor telepon ${phone} telah disalin.`
			})
		}
	}

	render() {
		return (
			<>
				{!this.state.data ?
					<Loader />
				:
					<div className="row">
						<div className="col-12">
							<div className="row">
								<img className="img-fluid px-0" src={this.state.data.detail.image} alt={this.state.data.detail.name} />
							</div>
						</div>
						<div className="col-12">
							<div className="row detail-container">
								<div className="col-12">
									<h1>{this.state.data.detail.name}</h1>
								</div>
								
								{this.state.data.detail.tags ?
									<div className="col-12 mb-3">
										{this.state.data.detail.tags.map(tag => 
											<span key={tag} className="badge badge-med">{tag}</span>
										)}
									</div>
								: null}

								<div className="col-12">
									<div className="row">
										<div className="col-1"><p><FontAwesomeIcon icon={faLocationDot} /></p></div>
										<div className="col"><p>{this.state.data.detail.address}</p></div>
									</div>
									
									{this.state.data.detail.phone ?
										<div className="row">
											<div className="col-1"><p><FontAwesomeIcon icon={faSquarePhone} /></p></div>
											<div className="col"><p>{this.state.data.detail.phone}</p></div>
										</div>
									: null}
								</div>
								<div className="col-12">
									<div className="row">
										<div className="col-6 text-center"><ActionButton text="ARAHKAN" icon={faLocationArrow} onClick={() => this.props.redirect(this.state.data.detail.gmaps_url)} /></div>

										{this.state.data.detail.phone ?
											<div className="col-6 text-center"><ActionButton text="HUBUNGI" icon={faPhone} onClick={() => this.handlePhone(this.state.data.detail.phone)} /></div>
										: null}
									</div>
								</div>
								<div className="col-12 mt-3">
									<h2>Rekomendasi Untuk Anda</h2>

									<div className="row mb-3">
										<Recommendation {...this.props} list={this.state.data.recommendation} onClick={param => this.changeDetail(param)} />
									</div>
									<div className="row mb-3">
										<Advertisement advertisement={this.state.data.advertisement} onClick={this.props.redirect} />
									</div>
								</div>
							</div>
						</div>

						<ToastAlert text={this.state.toastText} show={this.state.toastShow} position='middle-center' onClose={() => this.setState({toastShow: false})} />
					</div>
				}
			</>
		)
	}
}
