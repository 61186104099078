import React, { Component } from 'react';
import { Loader } from '../components/Other';
import { requestGet, requestToken } from '../axios';
import { ActionButton } from '../components/Button';
import { InputText } from '../components/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faPhone } from '@fortawesome/free-solid-svg-icons';
import { isMobile } from 'react-device-detect';

export default class Emergency extends Component {
    constructor(props) {
        super(props)
        this.state = {
			rawData: null,
			data: null,
			showContact: false,
			targetContact: null
        }
    }

    componentDidMount() {
		this.getContact()
	}

	getContact() {
		requestGet('/emergency/').then(response => {
			if (response.data.code === '00'){
				this.setState({
					rawData: response.data.data,
					data: response.data.data
				})
			}		
		}).catch(async(error) => {
			if (error.response.status === 401){
				await requestToken()
				this.getContact()
			}
		})
	}

	handlePhone(phone) {
		if (isMobile){
			window.location.href = `tel:${phone}`
		}
		else {
			navigator.clipboard.writeText(phone)

			this.setState({
				toastShow: true,
				toastText: `Nomor telepon ${phone} telah disalin.`
			})
		}
	}

	handleSearch(val) {
		if (!val) {
			this.setState({data: this.state.rawData})
		}
		else {
			let data = {}
			Object.keys(this.state.rawData).map(key => {
				if (key.toLowerCase().includes(val.toLowerCase())){
					data[key] = this.state.rawData[key]
				}
			})

			this.setState({data: data})
		}
	}


    render() {
        return (
            <>
				{!this.state.data ?
					<Loader />
				:
					<>
						<div className="mb-3">
							<InputText placeholder="Cari negara" onChange={(e) => this.handleSearch(e.target.value) }/>
						</div>
						<div className="my-3">
							<div className="col-12 contact-container-blue">
								<div className="row align-items-center">
									<div className="col-7 text-start"><h2 className="m-0 text-white">Zurich Assist</h2></div>
									<div className="col-5 text-end">
										<ActionButton 
											icon={faPhone} 
											text="Hubungi"
											style={{fontSize:12, margin:0, color:'#fff', borderColor:'#fff'}} 
											onClick={() => this.handlePhone('021-5082 5555')} />
									</div>
								</div>
							</div>

							{Object.keys(this.state.data).map((key, i) => {
								return (
									<div key={key} className="col-12 contact-container">
										<div className="row" onClick={() => this.setState({showContact: !this.state.showContact, targetContact: key})}>
											<div className="col-10 text-start"><h2 className="m-0">{key}</h2></div>
											<div className="col-2 text-end"><h2 className="m-0"><FontAwesomeIcon icon={faCaretDown} /></h2></div>
										</div>
										<div className="row pt-3" style={this.state.targetContact === key && this.state.showContact ? {display: 'block'} : {display: 'None'}}>
											{this.state.data[key].map((item, i) => {
												return (
													<div key={i + item.phone} className="col-12">
														<div className="row align-items-center">
															<div className="col"><p>{item.name}</p></div>
															<div className="col text-center">
																<ActionButton 
																	icon={faPhone} 
																	text="Hubungi"
																	style={{fontSize:12, margin:10}} 
																	onClick={() => this.handlePhone(item.phone)} />
															</div>
														</div>
													</div>
												)
											})}
										</div>
									</div>
								)
							})}
						</div>
					</>
				}
            </>
        )
    }
}
