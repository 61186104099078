import Table from 'react-bootstrap/Table';
import { useState, useRef, useLayoutEffect } from 'react';


export function Menu(props) {
	return (
		<div className="col-4 my-2" onClick={() => props.onClick(props.tag, props.url)}>
			<div className="home-menu-container">
				<div className="image">
					<img src={props.image} className="img-fluid" alt={props.name} />
				</div>
				<div className="action">{props.name}</div>
			</div>
		</div>
	)
}

export function Articles(props) {
	return (
		<Table responsive borderless className="h-100 m-0">
			<tbody>
				<tr className="h-100">
					{props.articles.map((item, i) => {
						return (
							<td key={i} className="p-1 align-top h-100">
								<div className="article-container h-100" onClick={() => props.onClick(item.url)}>
									<div className="image">
										<img className="img-fluid" src={item.image} alt={item.title}/>
									</div>
									<div className="title">{item.title}</div>
								</div>
							</td>
						)
					})}
				</tr>
			</tbody>
		</Table>
	)
}

export function Advertisement(props) {
	const targetRef = useRef();
	const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

	useLayoutEffect(() => {
		if (targetRef.current) {
			setDimensions({
				width: targetRef.current.offsetWidth,
				height: targetRef.current.offsetHeight
			});
		}
	}, []);

	return (
		<div className="col h-100" ref={targetRef}>
			<Table responsive borderless className="h-100 m-0">
				<tbody>
					<tr	className="h-100">
						{props.advertisement.map((item, i) => {
							return (
								<td key={i} className="p-1 align-top h-100">
									<div className="advertisement-container" onClick={() => props.onClick(item.url)}>
										<div className="image">
											<img className="img-fluid" src={item.image} alt={item.name}/>
										</div>
									</div>
								</td>
							)
						})}
					</tr>
				</tbody>
			</Table>
		</div>
	)
}