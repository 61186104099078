import React, { Component } from "react"
import { Modal, Toast, ToastContainer } from "react-bootstrap"


export class Loader extends Component {
    render() {
        return (
            <div>
                <div className="row vh-100 justify-content-center align-content-center">
                    <div className="spinner-border color-main" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        )
    }
}

export function ModalAlert(props) {
    return (
        <Modal centered show={props.show} onHide={props.onHide}>
            <Modal.Body className="text-center p-3">{props.content}</Modal.Body>
        </Modal>
    )
}

export function ToastAlert(props) {
	return (
		<ToastContainer position={props.position}> 
			<Toast show={props.show} animation autohide delay={1000} onClose={props.onClose}>
				<Toast.Body>{props.text}</Toast.Body>
			</Toast>
		</ToastContainer>
	)
}