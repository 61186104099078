import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';


export function SubmitButton(props) {
	return (
		<>
			{props.loading ?
				<div className="spinner-border color-main" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>	
			:
				<div className="form-group d-grid my-3">
					<input type="submit" className="btn main-button my-3" value={props.text} />
				</div>
			}
		</>
	)
}

export function LocationButton(props) {
	return (
		<div className="form-group d-grid">
			<button className="btn location-button my-3" onClick={props.onClick}>
                <FontAwesomeIcon icon={faLocationDot} /> Gunakan Lokasi Saat Ini
            </button>
		</div>
	)
}

export function ActionButton(props) {
	return (
		<>
			{props.loading ?
				<div className="spinner-border color-main" role="status">
					<span className="visually-hidden">Loading...</span>
				</div>	
			:
				<div className="form-group d-grid">
					<button className="btn btn-outline-primary action-button" onClick={props.onClick} style={props.style}>
						{props.icon ? <FontAwesomeIcon icon={props.icon} /> : null} {props.text}
					</button>
				</div>
			}
		</>
	)
}
