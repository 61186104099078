import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Result from '../screens/Result';


export default function ResultContainer(props) {
    let navigate = useNavigate()
    let navigation = useLocation()

	function goToDetail(param) {
		navigate('/detail', {state: {'param': param}})
    }

    return <Result {...props} 
		navigate={navigate} 
        navigation={navigation}
		goToDetail={goToDetail} />
}