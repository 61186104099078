import Select from 'react-select';


export function InputText(props) {
	return (
		<div className="form-group mb-3">
			<label className="form-label">{props.label}</label>
			<input 
				type="text" 
				className="form-control"
				name={props.name} 
				required={props.required}
				onChange={props.onChange} 
				value={props.value} 
				maxLength={props.maxLength} 
				minLength={props.minLength}
				disabled={props.disabled}
				placeholder={props.placeholder} />
		</div>
	)
}

export function InputNumber(props) {
	return (
		<div className="form-group mb-3">
			<label className="form-label">{props.label}</label>
			<input 
				type="number" 
				pattern="[0-9]*"
				className="form-control"
				name={props.name} 
				required={props.required}
				onChange={props.onChange} 
				value={props.value} 
				maxLength={props.maxLength} 
				minLength={props.minLength}
				disabled={props.disabled}
				placeholder={props.placeholder} />
		</div>
	)
}

export function SelectLocation(props) {
    return (
        <div className="form-group form-filter mb-3">
            <select 
                name={props.name}
                required={props.required}
				onChange={props.onChange} 
				value={props.value}
                disabled={props.disabled} >
                    <option value="">{props.placeholder}</option>
                    {props.options.map((item, i) => {
                        return (
                            <option key={i} value={item.value}>{item.text}</option>
                        )
                    })}
            </select>
        </div>
    )
}

export function FilterSelect(props) {
	return (
		<div>
			<Select 
				isMulti 
				onChange={props.onChange}
				name={props.name} 
				options={props.options} 
				className="basic-multi-select" 
				classNamePrefix="select"
				placeholder={props.placeholder} />
		</div>
	)
}
